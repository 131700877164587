import { ChartOptions, ChartTooltipModel, ChartTooltipModelBody, ChartType } from 'chart.js';
import { ActivityPriorityType } from 'src/app/core/services/http/activity-priority/enums/activity-priority-type';
import { AppRoute } from '../constants/app.route';
import { Authority } from '../constants/authority';
import { Status } from '../core/services/http/general/enums/status';
import { handleTooltipPositionOnCursorMove } from '../core/utils/utils';
import { StudentAttendanceType } from '../core/services/http/student-attendance/enums/student-attendance-type';
import { BulkType } from '../features/schedule/schedule-dates-modal/bulk-dates-form/enums/bulk-type';
import { CalendarViewTab } from '../shared/date-picker/enums/calendar-view-tab';

export const AppConfig = {
  Api: { maxUploadFileSizeMB: 50, pageSize: 50, pageSizeAll: 1000, headerXTotalName: 'x-pagination-total-count' },
  AutoComplete: { minTermLength: 2, debounceTimeMs: 500, clearTimeOut: 4000, pageSize: 100 },
  Preloader: { debounceTimeMs: 400 },
  Inputs: { allowedSpecialCharacters: '', minTermLength: 2 },
  Datatable: {
    nullableDatatableCellSumbol: '-',
    defaultColumnWidth: 100
  },
  DropDown: { ActivityTypeAllowTreeLevel: 1 },
  Language: { defaultLanguage: 'en', defaultLocale: 'en-US' },
  Calendar: { switcherTodayPlusDaysCount: 4, intervalUpdate: 30000 },
  Alert: { dismissOnTimeout: 7000 },
  GraduationYearMinusLimitInYears: 1,
  GraduationYearPlusLimitInYears: 14,
  Features: {
    notifications: true
  },
  Formats: {
    MonthDayFormat: 'MMM dd',
    DateApiFormat: 'Y-MM-dd',
    DateApiFormatFull: 'YYYY-MM-ddTHH:mm:ss',
    DateFormat: 'MMM d y',
    DateTimeFormat: 'MMM d y HH:mm',
    DateTimeNumericFormat: 'MM/dd/yyyy hh:mm a',
    DateTimeFormatMidDay: 'MMM d y HH:mm a',
    DateShortFormat: 'MM-dd-YYYY',
    DayFormat: 'EEE',
    TimeFormat: 'hh:mm',
    TimeFormatMidDay: 'hh:mm a',
    DateFormatEEE: 'EEE MMM d y',
    DayDateFormat: 'MMM d'
  },
  Table: { elapsedTimeBeforeEmptyMessage: 4000 },
  ScheduleStatus: { active: 'status-active', inActive: 'status-inactive' },
  Schedule: {
    defaultBulkType: BulkType.EverySlot,
    // @todo Once we have defined API mapping describing all states
    // (why student can't register for particular activity this should be removed)
    showActivityNAMessage: false
  },
  DateManagerService: {
    maxDateYears: 50
  },
  DefaultCalendarViewTabRoleMapping: {
    [Authority.Student]: CalendarViewTab.Day,
    [Authority.Admin]: CalendarViewTab.Week,
    [Authority.Staff]: CalendarViewTab.Week,
    [Authority.Teacher]: CalendarViewTab.Week,
    [Authority.Substitute]: CalendarViewTab.Week
  } as Record<Authority, CalendarViewTab>,
  DefaultRouteUserRoleMapping: {
    [Authority.Student]: AppRoute.Schedule,
    [Authority.Admin]: AppRoute.Dashboard,
    [Authority.Staff]: AppRoute.Schedule,
    [Authority.Teacher]: AppRoute.Schedule,
    [Authority.Substitute]: AppRoute.StudentAttendance
  } as Record<Authority, AppRoute>,
  DateTimePickers: {
    dateTimeRelatedStepMin: 15,
    dateTimeStepMin: 1,
    dateTimeAttendanceReminderStepMin: 5,
    defaultStartTimeSec: 7 * 60 * 60,
    defaultEndTimeSec: 7 * 60 * 60 + 15 * 60,
    filterDefaultEndDateHours: 24,
    countWeekends: false
  },
  BsConfig: {
    adaptivePosition: true,
    showClearButton: true,
    clearPosition: 'center',
    dateInputFormat: 'MM-DD-YYYY',
    daysDisabled: [6, 0],
    datesDisabled: [] as Date[]
  },
  SchoolYearResetCalendarConfig: {
    allowFromStartMonth: 2, // March
    allowToEndMonth: 5,     // June
    allowStartDay: 1,       // 1st of March
    allowToDay: 30,         // 30th of June
    rangeFromMonth: 6,      // July
    rangeToMonth: 8,        // September
    rangeFromDay: 2,        // 2 July
    rangeToDay: 30          // 30 September
  },
  EditorConfig: {
    minHeight: '200px',
    editable: true,
    enableToolbar: true,
    showToolbar: true,
    sanitize: true,
    customClasses: [
      {
        name: "quote",
        class: "quote",
      }],
    toolbarHiddenButtons: [
      ['fontName'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  },
  CalendarViewTabFormatMapping: {
    [CalendarViewTab.Day]: ['EEE', 'MMM d y', ''],
    [CalendarViewTab.Week]: ['W', 'MMM y', 'WEEK'],
    [CalendarViewTab.TodayPlus]: ['W', 'MMM y', 'WEEK'],
    [CalendarViewTab.Month]: ['MMM', 'y', '']
  } as Record<CalendarViewTab, string[]>,
  PriorityTypeClassMapping: {
    [ActivityPriorityType.AdminMustAttend]: 'priority-ama',
    [ActivityPriorityType.PriorityRoster]: 'priority-pr',
    [ActivityPriorityType.TeacherRequested]: 'priority-tr',
    [ActivityPriorityType.RegularRoster]: 'priority-rr',
    [ActivityPriorityType.OptionalRoster]: 'priority-or',
    [ActivityPriorityType.AdvertiseActivity]: 'priority-aa',
    [ActivityPriorityType.NormalActivity]: 'priority-na'
  } as Record<ActivityPriorityType, string>,
  PriorityTypeColorMapping: {
    [ActivityPriorityType.AdminMustAttend]: '#DCBA07',
    [ActivityPriorityType.PriorityRoster]: '#847CF3',
    [ActivityPriorityType.TeacherRequested]: '#308DEB',
    [ActivityPriorityType.RegularRoster]: '#D4D0FA',
    [ActivityPriorityType.OptionalRoster]: '#D96D04',
    [ActivityPriorityType.AdvertiseActivity]: '#379B7E',
    [ActivityPriorityType.NormalActivity]: '#92A7BA'
  } as Record<ActivityPriorityType, string>,
  StudentAttendanceTypeClassTransMapping: {
    [StudentAttendanceType.Absent]: ['danger', 'ABSENT', 'ftmi-cross'],
    [StudentAttendanceType.Present]: ['success', 'PRESENT', 'ftmi-check'],
    [StudentAttendanceType.LateUnexcused]: ['warning', 'LATE_UNEXCUSED', 'ftmi-check'],
    [StudentAttendanceType.LateExcused]: ['warning', 'LATE_EXCUSED', 'ftmi-check']
  } as Record<StudentAttendanceType, [string, string, string]>,
  ColumnNameWidthMapping: {
    actions: 200,
    controlledAccessPeriod: 90,
    cutoffDays: 105,
    cutoffTime: 100,
    date: 120,
    startDate: 100,
    endDate: 100,
    startTime: 100,
    endTime: 100,
    daysRemaining: 90,
    defaultSubstitutePlan: 220,
    email: 250,
    graduationYear: 100,
    maxAttendees: 100,
    membershipCount: 90,
    priority: 90,
    status: 80,
    scheduled: 90,
    submit: 60,
    ['role.code']: 90,
    ['user.email']: 250
  } as Record<string, number>,
  ChartColorLabelClassesMapping: {
    '#92a7ba': 'typeNormal',
    '#379b7e': 'typeAdvertise',
    '#d96d04': 'typeOptionalRoster',
    '#d4d0fa': 'typeRegularRoster',
    '#847cf3': 'typePriorityRoster',
    '#dcba07': 'typeAMA',
    '#f24c42': 'typeWithoutActivities',
    '#baca13': 'typeWithActivities',
    '#ffcb8d': 'typeExcluded',
    '#fbaf97': 'typeNotRegistered',
    '#baca12': 'typeRegistered', // todo #baca13
    '#89cef6': 'typeAttendancePending',
    '#00a2ff': 'typeAttendanceTaken',
    '#baca11': 'typeStudentPresent',
    '#ffcc90': 'typeStudentLateExcused',
    '#feaf96': 'typeStudentLateUnexcused'
  } as Record<string, string>,
  MaxSharedAttendance: 5,
  FormControls: {
    defaultStatusValue: Status.Active
  },
  Bootstrap: {
    accordionGroup: {
      isOpen: true
    }
  },
  CutoffTime: {
    cutoffMinutesShiftBeforeStartTime: 1
  },
  QRCode: { smallScreen: { size: 256, fps: 10 }, largeScreen: { size: 500, fps: 10 } },
  MetricColorTranslationMapping: {
    countWithoutActivities: {
      translationkey: 'Without activities',
      backgroundColor: '#f24c42'
    },
    countWithActivities: {
      translationkey: 'With Activities',
      backgroundColor: '#baca13'
    },
    countExcluded: {
      translationkey: 'Excluded',
      backgroundColor: '#ffcb8d'
    },
    countNotRegistered: {
      translationkey: 'Not Registered',
      backgroundColor: '#fbaf97'
    },
    countRegistered: {
      translationkey: 'Registered',
      backgroundColor: '#baca13'
    },
    countAttendancePending: {
      translationkey: 'Attendance Pending',
      backgroundColor: '#89cef6'
    },
    countAttendanceTaken: {
      translationkey: 'Attendance Taken',
      backgroundColor: '#00a2ff'
    },
    countPresent: {
      translationkey: 'Present',
      backgroundColor: '#BACA13'
    },
    countAbsent: {
      translationkey: 'Absent',
      backgroundColor: '#F24C42'
    },
    countLateExcused: {
      translationkey: 'Late Excused',
      backgroundColor: '#FFCB8D'
    },
    countLateUnexcused: {
      translationkey: 'Late Unexcused',
      backgroundColor: '#FBAF97'
    }
  } as Record<string, { translationkey: string; backgroundColor: string }>,
  ChartOptions: {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    cutoutPercentage: 68,
    legend: {
      display: false,
      position: 'chartArea',
      labels: {
        boxWidth: 15,
        fontColor: '#66738f',
        fontFamily: '"Open Sans", sans-serif',
        padding: 4
      }
    },
    tooltips: {
      enabled: false,
      xPadding: 17,
      yPadding: 14,
      backgroundColor: '#ffffff',
      bodyFontColor: '#66738F',
      cornerRadius: 8,
      borderWidth: 1,
      borderColor: '#d1dae1',
      custom: (context: ChartTooltipModel) => {
        // Tooltip Element
        let tooltip: any = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltip) {
          tooltip = document.createElement('div');
          tooltip.id = 'chartjs-tooltip';
          tooltip.innerHTML = '<table></table>';
          tooltip.style.backgroundColor = '#FFFFFF'; //
          tooltip.style.borderColor = '#B2BABB';
          tooltip.style.borderWidth = 'thin';
          tooltip.style.borderStyle = 'solid';
          tooltip.style.borderRadius = '5px';
          tooltip.style.padding = '5px';
        }

        // Hide if no tooltip
        if (context.opacity === 0) {
          tooltip.style.opacity = '0';
          tooltip?.remove(); // Removing Tooltip from DOM
          document.body.removeEventListener('mousemove', handleTooltipPositionOnCursorMove, false); // Removing Event Listener
          return;
        } else {
          tooltip.style.opacity = '1';
        }

        // Set caret Position
        tooltip.classList.remove('above', 'below', 'no-transform');
        if (context.yAlign) {
          tooltip.classList.add(context.yAlign);
        } else {
          tooltip.classList.add('no-transform');
        }

        // Set Text
        if (context.body) {
          //          let titleLines = context.title || [];
          const bodyLines = context.body.map((item: ChartTooltipModelBody) => item.lines);
          let innerHtml = '<thead></thead><tbody>';

          bodyLines.forEach(body => {
            const name = body[0].split(':')[0];
            const value = body[0].split(':')[1];

            innerHtml +=
              '<tr><td style="padding: 0px"><div style="font-family: Open Sans, sans-serif;padding-left: 6px;padding-right: 6px;color: #66738f;font-size: 0.75rem;line-height: 0.875rem;">' +
              name +
              ': ' +
              value +
              '</div></td></tr>';
          });
          innerHtml += '</tbody>';

          const tableRoot: any = tooltip.querySelector('table');
          tableRoot.innerHTML = innerHtml;

          document.body.addEventListener('mousemove', handleTooltipPositionOnCursorMove, false);
          tooltip.style.zIndex = '9999';
          tooltip.style.position = 'absolute';

          document.body.appendChild(tooltip);
        }
      }
    }
  } as ChartOptions,
  ChartType: 'doughnut' as ChartType,
  SchoolYear: {
    StartYear: 2023,
    StartMonth: 6,
    StartDate: 1
  }
};
