<div class="{{ className }}">
    <label>{{ 'FLEX_PERIOD' | translate }}</label>
    <ng-container *ngIf="form && controlName">
      <app-auto-complete
        (selectedItemChange)="onSelectChangeFlex($event)"
        (searchChange)="onSearchChangeFlex($event)"
        [itemsData]="flexs"
        [selectedItem]="form.get(controlName)?.value"
        [searchOnClick]="true"
        [minTermLength]="0"
      ></app-auto-complete>
    </ng-container>
  </div>
  