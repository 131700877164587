import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';

@Component({
  selector: 'app-auto-assign-status-checkbox',
  templateUrl: './auto-assign-status-checkbox.component.html'
})
export class AutoAssignStatusCheckboxComponent extends DestroyNotifierComponent implements OnInit {
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'autoAssign';
  @Input() label = 'ALLOW_AUTOASSIGN';
  @Input() emitEvent = false;

  ngOnInit(): void {
    this.onStatusChange();
  }

  private onStatusChange(): void {
    this.form
      ?.get(this.controlName)
      ?.valueChanges.pipe(takeUntil(this.destroyNotify))
      .subscribe(value => {
        if (value === false) {
          this.form?.get(this.controlName)?.setValue(false, { emitEvent: this.emitEvent });
        } else {
          this.form?.get(this.controlName)?.setValue(true, { emitEvent: this.emitEvent });
        }
      });
  }

  get isDisabled(): boolean {
    return this.form?.get(this.controlName)?.disabled ?? false;
  }
}
