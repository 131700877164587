<ng-container *ngIf="form">
  <div *ngIf="activityPriorities" [formGroup]="form" class="{{ className }}">
    <label for="priority">{{ 'PRIORITY' | translate }}</label>
    <ng-select
      [placeholder]="'SELECT_PRIORITY' | translate"
      [items]="activityPriorities"
      [bindLabel]="'title'"
      [bindValue]="'uuid'"
      formControlName="priority"
      class="pl-0 pr-0"
    >
      <ng-template ng-label-tmp let-item="item">
        <span appPriorityClass [type]="item.type" class="priority-badge"> {{ item.abbreviation }}</span>
        <span> {{ item.title }}</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        <span appPriorityClass [type]="item.type" class="priority-badge"> {{ item.abbreviation }}</span>
        <span> {{ item.title }}</span>
      </ng-template>
    </ng-select>
  </div>
</ng-container>
