import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { ClassInputType } from 'src/app/shared/shared-extensions/types/class-input-type';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';
import { FlexService } from 'src/app/core/services/http/flex/flex.service';
import { Flex } from 'src/app/core/services/http/flex/interfaces/flex';
import { FlexAdapterService } from 'src/app/core/services/http/flex/adapters/flex-adapter.service';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';

@Component({
  selector: 'app-auto-complete-flex',
  templateUrl: './auto-complete-flex.component.html',
  styleUrls: ['./auto-complete-flex.component.scss']
})
export class AutoCompleteFlexComponent extends DestroyNotifierComponent {
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'flex';
  @Input() className: ClassInputType = 'app-input';

  flexs: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private flexService: FlexService,
    private dateManagerService:DateManagerService,
    private flexAdapterService: FlexAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeFlex(item: AutoCompleteItem): void {
    if (this.controlName) {
      this.form?.get(this.controlName)?.setValue(item);
    }
  }

  onSearchChangeFlex(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<Flex[]>(
      this.flexService.getAll(this.paging, { filter, status: Status.Active }),
      this.destroyNotify,
      flexs => {
        const filteredFlex = flexs.filter((flex:any)=>{
          return !this.dateManagerService.isBeforeToday(flex.endDate)
        });
        this.flexs = this.flexAdapterService.flexToAutoCompleteItem(filteredFlex);
      }
    );
  }
}
