import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Status } from 'src/app/core/services/http/general/enums/status';

@Component({
  selector: 'app-status-checkbox',
  templateUrl: './status-checkbox.component.html'
})
export class StatusCheckboxComponent extends DestroyNotifierComponent implements OnInit {
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'status';
  @Input() label = 'ACTIVE';
  @Input() emitEvent = false;
  
  ngOnInit(): void {
    this.onStatusChange();
  }

  private onStatusChange(): void {
    this.form
      ?.get(this.controlName)
      ?.valueChanges.pipe(takeUntil(this.destroyNotify))
      .subscribe(value => {
        if (value === false || value === 0) {
          this.form?.get(this.controlName)?.setValue(Status.Inactive, { emitEvent: this.emitEvent });
        } else {
          this.form?.get(this.controlName)?.setValue(Status.Active, { emitEvent: this.emitEvent });
        }
      });
  }

  get isDisabled(): boolean {
    return this.form?.get(this.controlName)?.disabled ?? false;
  }
}
