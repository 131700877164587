import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AutoCompleteItem } from 'src/app/shared/auto-complete/interfaces/autocomplete-item';
import { Room } from '../interfaces/room';
import { RoomRequest } from '../interfaces/room-request';

@Injectable({
  providedIn: 'root'
})
export class RoomAdapterService {
  formGroupToRoomRequest(form: UntypedFormGroup): RoomRequest | undefined {
    if (!form) {
      return;
    }  

      return {
        name: form.controls.name.value,
        capacity: Number(form.controls.capacity.value),
        status: form.controls.status.value,
        building: form.controls.building.value,
        customCapacityToAdd : form.controls.enableCustomDefaultCapacities.value ? form.controls.defaultList.value: [],
        customCapacityToRemove: form.controls.customCapacityToRemove.value || undefined
      };
  }

  roomToAutoCompleteItem(rooms?: Room[]): AutoCompleteItem<Room>[] {
    if (!rooms) {
      return [];
    }

    return rooms.map(item => {
      return {
        value: item.uuid,
        label: `${item.name}`,
        extraLabels: {
          firstLabel: item.name,
          value: item.uuid
        }
      } as AutoCompleteItem<Room>;
    });
  }

  roomToFormGroupData(room: Room, form: UntypedFormGroup): void {
    if (!form) {
      return;
    }

    form.patchValue({
      name: room.name,
      capacity: room.capacity,
      building: room.building.uuid,
      status: room.status
    });
  }

  roomToRoomRequest(room: Room): RoomRequest | undefined {
    if (!room) {
      return;
    }

    const roomRequest: RoomRequest = {
      name: room.name,
      capacity: room.capacity,
      status: room.status,
      building: room.building.uuid
    };

    return roomRequest;
  }
}
