import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { AppRoute } from 'src/app/constants/app.route';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { ActivityPriorityType } from 'src/app/core/services/http/activity-priority/enums/activity-priority-type';
import { ActivityPriority } from 'src/app/core/services/http/activity-priority/interfaces/activity-priority';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { RosterAdapterService } from 'src/app/core/services/http/roster/adapters/roster-adapter.service';
import { Roster } from 'src/app/core/services/http/roster/interfaces/roster';
import { RosterService } from 'src/app/core/services/http/roster/roster.service';
import { Student } from 'src/app/core/services/http/user/interfaces/student';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { mergeValueChangesByFormControlName } from 'src/app/core/utils/utils';
import { AutoCompleteItem } from 'src/app/shared/auto-complete/interfaces/autocomplete-item';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { ConfirmationModal } from 'src/app/shared/confirmation-modal/interfaces/confirmation.modal';
import { FormGroupService } from 'src/app/shared/form-error/form-group.service';
import { resetRosterFormControls } from '../../../form-group/form.group';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { Alert } from 'src/app/shared/alert/types/alert';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { EventType } from 'src/app/constants/events-type';

@Component({
  selector: 'app-activity-roster-students',
  templateUrl: './activity-roster-students.component.html',
  styleUrls: ['./activity-roster-students.component.scss']
})
export class ActivityRosterStudentsComponent extends DestroyNotifierComponent implements OnInit {
  @Input() form: UntypedFormGroup | undefined;
  @Input() isAutoAssignAllowed:boolean | undefined;

  rosters: AutoCompleteItem[] = [];
  rosterStudents: any;
  selectedRoster: any;
  activityPriorities: ActivityPriority[] | undefined;
  isDisabled = false;

  activityPriorityType = ActivityPriorityType;
  resetRosterFormControls = resetRosterFormControls;
  mergeValueChangesByFormControlName = mergeValueChangesByFormControlName;

  constructor(
    private cdr: ChangeDetectorRef,
    private rosterService: RosterService,
    private rosterAdapterService: RosterAdapterService,
    private router: Router,
    private translate: TranslateService,
    private modalService: BsModalService,
    private formGroupService: FormGroupService,
    private observableWrapperService: ObservableWrapperService,
    private eventManager: EventManagerService
  ) {
    super();
  }

  ngOnInit() {
    this.onRosterChange();
  }

  ngAfterViewInit(){
    if(this.form){
      this.selectedRoster = this.form.controls.defaultRoster.value;
      this.rosterStudents = this.form.controls.rosterStudents.value;
      
      if(this.form.controls.defaultRoster?.disabled){
        this.isDisabled = this.form.controls.defaultRoster?.disabled;
        this.cdr.detectChanges()
      }
     }
  }

  onSelectChangeRoom(items: AutoCompleteItem[]): void {
    if (!this.form) {
      return;
    }

    this.formGroupService.setValue(
      this.form.controls.defaultRoom,
      items.map(item => item.value)
    );
  }

  onSelectChangeRoster(item: AutoCompleteItem): void {
    if (item) {
      this.form?.controls.excludedStudents.setValue([]);
      this.form?.controls.defaultRoster.setValue(item);
      this.rosterService.getById(item.value).subscribe((roster: Roster) => {

        if(this.form?.controls.additionalStudents && this.form?.controls.additionalStudents.value.length && this.isThereDuplicateInAdditionalStudents(roster.membershipCollection)){
            this.form?.controls.defaultRoster.setValue(null);
            this.form?.controls.rosterStudents.setValue([]);
            return;
        }

        this.form?.controls.rosterStudents.setValue(roster.membershipCollection as Student[]);
      });
    }
  }

  onSelectChangeAdditionalStudent(item:any): void {
    this.form?.controls.additionalStudents.setValue(item as Student[]);
  }

  onSearchChangeRoster(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<Roster[]>(
      this.rosterService.getAll(undefined, { filter, status: Status.Active,owner: this.form?.controls.owner.value || undefined }),
      this.destroyNotify,
      rosters => {
        this.rosters = this.rosterAdapterService.rosterToAutoCompleteItem(rosters);
      }
    );
  }

  onUpdateExclusions(student: Student): void {
    const isStudentExcluded = this.isStudentExcluded(student);

    // Adds student if no exist in the excludedStudents list
    if (!isStudentExcluded) {
      const excludedStudents = this.form?.controls.excludedStudents.value;
      excludedStudents.push(student);
      this.form?.controls.excludedStudents.setValue(excludedStudents);
    } else {
      // If student is already in the excludedStudents list
      const filteredStudents = this.form?.controls.excludedStudents.value.filter(
        (excludedStudent: Student) => excludedStudent.uuid !== student.uuid
      );
      this.form?.controls.excludedStudents.setValue(filteredStudents);
    }
  }

  async onEditRoster(): Promise<void> {
    const translations = await this.translate.get(['CHANGES_WILL_BE_LOST', 'CONFIRM']).toPromise();

    const initialState = {
      modal: {
        title: translations.CHANGES_WILL_BE_LOST,
        buttonText: translations.CONFIRM,
        callback: () => {
          this.onConfirmEditRoster();
        }
      } as ConfirmationModal
    };

    this.modalService.show(ConfirmationModalComponent, { initialState, class: 'prompt-modal', backdrop: 'static' });
  }

  onClearChange(): void {
    this.resetRosterFormControls(this.form);
  }

  isStudentExcluded(student: Student): boolean {
    return (
      this.form?.controls.excludedStudents.value.find(
        (excludedStudent: Student) => excludedStudent.uuid === student.uuid
      ) !== undefined
    );
  }

  private updateMandateActivity() {
    if (!this.form) {
      return;
    }

    const mandateActivity = this.form.controls.activityPriority.value.type;

    if (
      mandateActivity === ActivityPriorityType.AdminMustAttend ||
      mandateActivity === ActivityPriorityType.PriorityRoster ||
      mandateActivity === ActivityPriorityType.RegularRoster
    ) {
      const additionalStudents = this.form.controls.additionalStudents.value?.length;

      const rosterStudents =
        this.form.controls.rosterStudents.value?.length - this.form.controls.excludedStudents.value?.length;

      let studentCount = additionalStudents + rosterStudents;

      if (studentCount < 0) {
        studentCount = 0;
      }
      
      this.formGroupService.setValue(this.form.controls.maxAttendees, studentCount);
    }
  }

  private onRosterChange(): void {
    if (!this.form) {
      return;
    }
    const merged = this.mergeValueChangesByFormControlName(
      ['defaultRoster', 'rosterStudents', 'excludedStudents', 'additionalStudents'],
      this.form
    );

    if (merged) {
      merged.pipe(takeUntil(this.destroyNotify)).subscribe((value: any) => {
        if (value) {
          this.updateMandateActivity();
        }
      });
    }
  }

  private onConfirmEditRoster(): void {
    const rosterUuid = this.form?.controls.defaultRoster.value as AutoCompleteItem;

    this.router.navigateByUrl(`${AppRoute.Roster}/${AppRoute.Edit}/${rosterUuid.value}`, {
      state: { navigateTo: this.router.url }
    });
  }

  isThereDuplicateInAdditionalStudents(roster: Student[]){

    const duplicateStudents:any = roster.filter((objA:any) =>
      this.form?.controls.additionalStudents.value.some((objB:any) => objA.uuid === objB.value)
    );

    if(!duplicateStudents.length){
      return false;
    }

    const alert: Alert = {
      type: 'danger',
      dismissOnTimeout: 0,
      translateKey: 'FOLLOWING_STUDENTS_ALREADY_ADDED',
    };

    alert.messageDetail = duplicateStudents.map((students:any) => students.user.email).join(', ');
    this.eventManager.broadcast(new EventContent(EventType.AlertMessage, alert));

    return true;
  }
}
