import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { RoomAdapterService } from 'src/app/core/services/http/room/adapters/room-adapter.service';
import { Room } from 'src/app/core/services/http/room/interfaces/room';
import { RoomService } from 'src/app/core/services/http/room/room.service';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { ClassInputType } from 'src/app/shared/shared-extensions/types/class-input-type';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-room',
  templateUrl: './auto-complete-room.component.html'
})
export class AutoCompleteRoomComponent extends DestroyNotifierComponent {
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'defaultRoom';
  @Input() className: ClassInputType = 'app-input';
  @Input() disabled = false;

  rooms: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private roomService: RoomService,
    private roomAdapterService: RoomAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeRoom(item: AutoCompleteItem): void {
    if (this.controlName) {
      this.form?.get(this.controlName)?.setValue(item);
      this.form?.get(this.controlName)?.markAsDirty()
    }
  }

  onSearchChangeRoom(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<Room[]>(
      this.roomService.getAll(this.paging, { filter, status: Status.Active }),
      this.destroyNotify,
      rooms => {
        this.rooms = this.roomAdapterService.roomToAutoCompleteItem(rooms);
      }
    );
  }
}
