import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { getPriorityClassByType } from 'src/app/shared/data-table/utils/utils';
import { ActivityPriorityType } from '../../services/http/activity-priority/enums/activity-priority-type';

@Directive({
  selector: '[appPriorityClass]'
})
export class PriorityClassDirective implements OnChanges {
  @Input() type: ActivityPriorityType | undefined;

  readonly priorityClasses = [
    'priority-ama',
    'priority-pr',
    'priority-tr',
    'priority-rr',
    'priority-or',
    'priority-aa',
    'priority-na'
  ];

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    const cssClass = getPriorityClassByType(this.type);
    const dOMTokenList = this.el.nativeElement.classList as DOMTokenList;

    // If class that we should to add already exist breaks further execution of the method
    if (!cssClass || dOMTokenList.contains(cssClass)) {
      return;
    }

    this.removeExistingClass(dOMTokenList);

    this.el.nativeElement.classList.add(cssClass);
  }

  private removeExistingClass(dOMTokenList: DOMTokenList): void {
    const isTherePriorityClass = dOMTokenList.value.indexOf('priority-') !== -1;

    if (!isTherePriorityClass) {
      return;
    }

    dOMTokenList.forEach((value: string) => {
      if (this.priorityClasses.includes(value)) {
        dOMTokenList.remove(value);
      }
    });
  }
}
