import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { ClassInputType } from 'src/app/shared/shared-extensions/types/class-input-type';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';

@Component({
  selector: 'app-auto-complete-owner',
  templateUrl: './auto-complete-owner.component.html',
  styleUrls: ['./auto-complete-owner.component.scss']
})
export class AutoCompleteOwnerComponent extends DestroyNotifierComponent{
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'owner';
  @Input() className: ClassInputType = 'app-input';
  @Output() selectChange = new EventEmitter();
  
  owners: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeRoom(item: AutoCompleteItem): void {
    if (this.controlName) {
      let owner = item;

      if(item){
        owner = this.userAdapterService.ownerToAutoCompleteItem(item.extraValues?.firstValue.user);
      }

      this.form?.get(this.controlName)?.setValue(owner);
      this.selectChange.emit(owner);
      this.form?.get(this.controlName)?.markAsDirty();
    }
  }

  onSearchChangeRoom(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getEmployeesByRole(undefined, { filter, status: Status.Active }),
      this.destroyNotify,
      owners => {
        this.owners = this.userAdapterService.userToAutoCompleteItem(owners);
      }
    );
  }
}
